import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width={96}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M8 1 5.727 5.61.64 6.345l3.68 3.59L3.45 15 8 12.61 12.55 15l-.87-5.065 3.68-3.585-5.085-.74L8 1Zm20 0-2.274 4.61-5.085.735 3.68 3.59L23.45 15 28 12.61 32.55 15l-.87-5.065 3.68-3.585-5.085-.74L28 1Zm20 0-2.274 4.61-5.085.735 3.68 3.59L43.45 15 48 12.61 52.55 15l-.87-5.065 3.68-3.585-5.085-.74L48 1Zm17.726 4.61-5.085.74 3.68 3.585L63.45 15 68 12.61V1l-2.275 4.61Z"
            fill="#143B6B"
        />
        <path
            d="m70.274 5.61 5.085.74-3.68 3.585.87 5.065L68 12.61V1l2.275 4.61ZM88 1l-2.274 4.61-5.085.735 3.68 3.59L83.45 15 88 12.61 92.55 15l-.87-5.065 3.68-3.585-5.085-.74L88 1Z"
            fill="#DDE8EE"
        />
    </svg>
);

export default SvgComponent;
