import React from 'react';

const WooCommerceLogo = ({ width = '58', height = '25', className }) => (
    <svg
        width={width}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="86"
        height="52"
        fill="none">
        <path
            fill="#9B5C8F"
            fill-rule="evenodd"
            d="M.789 5.216C1.959 2.38 4.899.376 7.979.382 31.141.374 54.303.385 77.466.376a7.965 7.965 0 0 1 5.503 2.063c1.697 1.518 2.664 3.788 2.617 6.062-.01 8.88.016 17.756-.012 26.635-.017 4.012-3.521 7.592-7.531 7.702-8.447.036-16.898.003-25.346.017 1.135 2.796 2.284 5.59 3.42 8.388-5.033-2.771-10.015-5.64-15.075-8.352-11.112-.098-22.233.008-33.347-.055C3.692 42.723.2 39.145.178 35.142c-.033-8.692 0-17.386-.016-26.08-.031-1.305.092-2.64.627-3.846Z"
            clip-rule="evenodd"
        />
        <path
            fill="#fff"
            fill-rule="evenodd"
            d="M34.764 5.863c1.283-1.365 3.718-.922 4.665.621.513.81.558 1.891.145 2.755-1.783 3.51-2.529 7.422-3.216 11.266-.812 5.012-1.487 10.09-1.224 15.177.072 1.297-.555 2.872-1.947 3.174-1.318.295-2.516-.568-3.343-1.498-3.783-4.068-5.923-9.34-7.467-14.598-1.56 3.08-3.127 6.159-4.64 9.263-1.059 2.099-2.176 4.24-3.868 5.906-.715.71-1.923 1.35-2.845.622-1.386-1.213-1.728-3.147-2.317-4.808-2.232-7.702-3.5-15.639-4.797-23.54-.296-1.318.255-2.895 1.57-3.421 1.061-.398 2.42-.454 3.326.327.63.563.803 1.435.921 2.23.91 6.117 1.995 12.214 3.472 18.22 2.53-4.796 5.045-9.602 7.567-14.403.376-.704.824-1.41 1.514-1.846.921-.592 2.288-.55 3.059.277.647.678.862 1.622 1.042 2.513.754 3.97 1.774 7.902 3.256 11.664.639-5.671 1.42-11.412 3.491-16.766.467-1.079.88-2.22 1.636-3.135ZM48.986 8.9c2.724-.334 5.663.571 7.518 2.642 1.512 1.712 2.361 3.933 2.647 6.18.462 3.845-.23 7.84-2.078 11.25-1.045 1.944-2.413 3.808-4.34 4.95-2.825 1.716-6.613 1.505-9.342-.305-2.18-1.493-3.404-4.037-3.903-6.573-1.002-5.737.711-12.135 5.057-16.141 1.247-1.076 2.802-1.804 4.44-2.003Zm.569 5.903c-1.308.435-2.135 1.633-2.838 2.748-1.762 2.84-2.272 6.583-.855 9.67.372.812.972 1.667 1.917 1.838 1.003.165 1.936-.41 2.667-1.035 1.61-1.38 2.412-3.456 2.802-5.494.437-2.319.213-4.889-1.148-6.875-.548-.81-1.62-1.177-2.545-.852Zm21.283-5.898c2.662-.33 5.55.49 7.414 2.477 1.667 1.8 2.568 4.206 2.823 6.624.392 3.715-.29 7.556-2.053 10.859-1.06 1.998-2.458 3.92-4.441 5.083-2.712 1.625-6.293 1.476-8.98-.126-1.816-1.093-3.023-2.959-3.718-4.92-1.906-5.999-.297-13.057 4.093-17.585 1.317-1.282 3.026-2.182 4.862-2.412Zm.577 5.907c-1.429.51-2.287 1.874-3.029 3.113-1.609 2.834-2.004 6.485-.55 9.463.393.825 1.088 1.654 2.072 1.7 1.07.038 1.991-.64 2.733-1.34 1.618-1.595 2.357-3.873 2.615-6.084.266-2.07-.079-4.294-1.284-6.031-.551-.81-1.636-1.155-2.557-.821Z"
            clip-rule="evenodd"
        />
    </svg>
);

export default WooCommerceLogo;
