import React from 'react';
import { Formik, Form, Field } from 'formik';
import ErrorIcon from '../../../../../SVGComponents/ErrorIcon';
import styles from './AboveTheFoldForm.module.scss';
import { submitSignupForm } from '../AboveTheFold.helpers';
import { debouncedEmailValidation } from '../AboveTheFold.helpers';
import { Input } from '@iwoca/orion';
import { useIntl } from 'react-intl';
import messages from './AboveTheFoldForm.messages';
import PadLockOpenIcon from '../../../../../SVGComponents/PadLockOpenIcon';
import PadLockClosedIcon from '../../../../../SVGComponents/PadLockClosedIcon';
import { useState } from 'react';
import { postToDataLayer } from '../../../../../util/googleTracking';
import { postToHubspot } from './postToHubspot';

const postSellerSubmitEventToDataLayer = () => {
    postToDataLayer({
        event: '[SEGMENT] Seller signup submitted success',
        category: 'iwocaPay',
        label: 'Landing page',
        value: undefined
    });
};

const AboveTheFoldForm = ({ submitInputRef, queryUrl }) => {
    const handleSubmitForm = async (values) => {
        const { applicantEmailAddress } = values;

        if (!applicantEmailAddress) {
            return;
        }

        submitSignupForm(values)
            .catch((e) => e)
            .then(async () => {
                postSellerSubmitEventToDataLayer();
                await postToHubspot(applicantEmailAddress);
                window.location.href = queryUrl;
            });
    };

    const initialValues = {
        applicantEmailAddress: '',
        email_marketing_opt_in: false
    };

    const [isInputFocused, setIsInputFocused] = useState(false);
    const isPadLockOpen = (props) => {
        const isValid = props.values.applicantEmailAddress && props.isValid;
        return isValid || isInputFocused;
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmitForm(values)}
            validateOnChange={false}>
            {(props) => (
                <Form data-testid="aboveTheFoldForm">
                    <div className={styles.ctaContainer}>
                        <EmailField
                            submitInputRef={submitInputRef}
                            form={props}
                            onFocusAndBlur={() =>
                                setIsInputFocused(!isInputFocused)
                            }
                        />
                        <button
                            data-ga-id="aboveTheFold___iwocapaycta"
                            className={styles.ctaButton}>
                            {isPadLockOpen(props) ? (
                                <PadLockOpenIcon
                                    className={styles.padLockIcon}
                                />
                            ) : (
                                <PadLockClosedIcon
                                    className={styles.padLockIcon}
                                />
                            )}
                            <span className={styles.ctaButtonText}>
                                See your dashboard
                            </span>
                        </button>
                    </div>
                    {!props.isValid && (
                        <div className={styles.errorMessages}>
                            <ErrorIcon />
                            <span>{props.errors?.applicantEmailAddress}</span>
                            <ShowErrorLoginCTA errorMessage={props} />
                        </div>
                    )}
                    <MarketingCheckbox />
                </Form>
            )}
        </Formik>
    );
};

const EmailField = ({ submitInputRef, form, onFocusAndBlur }) => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.emailSignupInput}>
            <Field
                name="applicantEmailAddress"
                validate={(_) => {
                    if (form.values['applicantEmailAddress'] === '') {
                        return formatMessage({
                            ...messages.please_enter_a_valid_email_address
                        });
                    }
                    return form.errors['applicantEmailAddress'];
                }}>
                {({ form, field }) => (
                    <>
                        <Input
                            {...field}
                            ref={submitInputRef}
                            data-testid="applicantEmailAddress"
                            id="applicantEmailAddress"
                            placeholder={formatMessage({
                                ...messages.email_placeholder
                            })}
                            type="email"
                            className={styles.emailInput}
                            onChange={async (e) => {
                                await form.handleChange(e);
                                await debouncedEmailValidation(form, e);
                            }}
                            onFocus={onFocusAndBlur}
                            onBlur={onFocusAndBlur}
                        />
                    </>
                )}
            </Field>
        </div>
    );
};

const MarketingCheckbox = () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.marketingOptionOptIn}>
            <Field name="email_marketing_opt_in">
                {({ field }) => (
                    <input
                        {...field}
                        id="email_marketing_opt_in"
                        type="checkbox"
                        checked={field.value}
                    />
                )}
            </Field>
            <span className={styles.marketingOptionLabel}>
                {formatMessage({
                    ...messages.marketing_sign_up_action
                })}
            </span>
        </div>
    );
};

const ShowErrorLoginCTA = ({ errorMessage: { errors } }) => {
    const { formatMessage } = useIntl();
    const alreadyRegisteredMessage = formatMessage({
        ...messages.email_already_registered
    });
    const { applicantEmailAddress: errorMessage } = errors;

    if (errorMessage !== alreadyRegisteredMessage) return null;

    return (
        <a className={styles.loginAnchor} href="/pay/dashboard">
            Login here
        </a>
    );
};

export default AboveTheFoldForm;
