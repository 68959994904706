import React from 'react';

import styles from './PillTabs.module.scss';

import classnames from 'classnames';

const PillTabs = (props) => {
    const { isFree, onChangeSelection, type } = props;
    const color = type === 'seller' ? 'yellow' : 'orange';

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.containerWrapper}>
                <div
                    className={classnames(styles.tabsContainer, {
                        [styles.payLaterSelected]: isFree
                    })}>
                    <div className={styles.tabsSubContainer}>
                        <div className={styles.headingContent}>
                            <h2>
                                {type === 'seller'
                                    ? 'How BNPL for business works'
                                    : 'How BNPL works for customers'}
                            </h2>
                            <div
                                className={classnames(
                                    styles.tabs,
                                    styles[type]
                                )}></div>
                        </div>
                        <div
                            className={classnames(
                                styles.pillButtons,
                                styles[type]
                            )}>
                            <div
                                onClick={() =>
                                    onChangeSelection(isFree ? !isFree : isFree)
                                }
                                className={classnames(
                                    styles.link,
                                    styles[color],
                                    {
                                        [styles.selected]: !isFree
                                    }
                                )}>
                                Pay Later
                            </div>
                            <div
                                onClick={() =>
                                    onChangeSelection(isFree ? isFree : !isFree)
                                }
                                className={classnames(
                                    styles.link,
                                    styles[color],
                                    {
                                        [styles.selected]: isFree
                                    }
                                )}>
                                Pay Now
                            </div>
                            <div className={styles.pillBackground}></div>
                            <span
                                className={classnames(
                                    styles.pill,
                                    styles[color]
                                )}></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PillTabs;
