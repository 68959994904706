import React from 'react';

const LinkedIn = ({ ...props }) => {
    return (
        <svg
            {...props}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.5859 0H1.58594C0.985937 0 0.585938 0.4 0.585938 1V23C0.585938 23.6 0.985937 24 1.58594 24H23.5859C24.1859 24 24.5859 23.6 24.5859 23V1C24.5859 0.4 24.1859 0 23.5859 0ZM7.68594 20.5H4.18594V9H7.78594V20.5H7.68594ZM5.88594 7.4C4.78594 7.4 3.78594 6.5 3.78594 5.3C3.78594 4.2 4.68594 3.2 5.88594 3.2C6.98594 3.2 7.98594 4.1 7.98594 5.3C7.98594 6.5 7.08594 7.4 5.88594 7.4ZM21.0859 20.5H17.4859V14.9C17.4859 13.6 17.4859 11.9 15.6859 11.9C13.7859 11.9 13.5859 13.3 13.5859 14.8V20.5H9.98594V9H13.3859V10.6C13.8859 9.7 14.9859 8.8 16.7859 8.8C20.3859 8.8 21.0859 11.2 21.0859 14.3V20.5Z"
                fill="#143B6B"
            />
        </svg>
    );
};

export default LinkedIn;
