import useQueryUrl from '../../../../util/useQueryUrl.hook';
import { fetchIsLoggedIn } from '../../../../util/authHelpers';

const useNavbar = () => {
    const { queryUrl } = useQueryUrl('/pay/onboarding/signup/');

    const isLoggedIn = fetchIsLoggedIn();

    const ctaProps = isLoggedIn
        ? { text: 'My account', href: '/pay/dashboard/' }
        : {
              text: 'Get started',
              href: queryUrl,
              dataGaId: 'navbar__iwocapaycta'
          };

    return { ctaProps, isLoggedIn };
};

export default useNavbar;
