import React, { useState, useEffect } from 'react';

import styles from './NavCTAButton.module.scss';
import classnames from 'classnames';

const useScrollHook = () => {
    const [scrollY, setY] = useState(
        typeof window !== 'undefined' ? window.scrollY : 0
    );

    useEffect(() => {
        const handleNavigation = (e) => {
            const window = e.currentTarget;
            if (typeof window !== 'undefined') {
                setY(window.scrollY);
            }
        };

        window.addEventListener('scroll', handleNavigation);
        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, []);

    return { scrollY };
};

const NavHidingCTAButton = ({
    setSubmitInputFocus,
    hiddenDepth = 350,
    ctaText = 'Start for free',
    dataGaId
}) => {
    const { scrollY } = useScrollHook();

    return (
        <button
            data-ga-id={dataGaId}
            onClick={() => {
                setSubmitInputFocus();
                window.scrollTo(0, 0);
            }}
            className={classnames(styles.ctaButton, {
                [styles.hidden]: scrollY < hiddenDepth
            })}>
            {ctaText}
        </button>
    );
};

export default NavHidingCTAButton;
