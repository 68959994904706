import React from 'react';

const TrustpilotStar = (props) => {
    return (
        <svg
            {...props}
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.1568 6.87409H12.0935L9.91238 0.00366211L7.73126 6.87409H0.667969L6.3744 11.126L4.19327 17.9964L9.91238 13.7445L15.6315 17.9964L13.4377 11.126L19.1568 6.87409Z"
                fill="#00B67A"
            />
        </svg>
    );
};

export default TrustpilotStar;
