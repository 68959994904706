import React from 'react';

import styles from './Plugins.module.scss';
import WooCommerceLogo from '../../../../SVGComponents/wooCommerceLogo';
import XeroLogo from '../../../../SVGComponents/xeroLogo';
import QuickbooksLogo from '../../../../SVGComponents/quickbooksLogo';

const Plugins = () => {
    return (
        <section>
            <div className={styles.Plugins}>
                <h2 className={styles.title}>
                    Integrates seamlessly with your sales process
                </h2>
                <h2 className={styles.mobileTitle}>
                    Integrates seamlessly with your sales process
                </h2>
                <p className={styles.featureText}>
                    We’ve partnered with the biggest names in ecommerce and
                    accounting software so you can easily accept business
                    payments and unlock growth by offering Buy Now Pay Later for
                    business customers.
                </p>
                <hr />
                <div className={styles.infoContainer}>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <WooCommerceLogo width="200" height="50" />
                        </div>
                        <h3>WooCommerce</h3>
                        <p>
                            Offer your eCommerce customers an easier way to pay
                            using our WooCommerce plugin, adding iwocaPay to
                            your checkout payment options.
                        </p>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <XeroLogo />
                        </div>
                        <h3>Xero Accounting</h3>
                        <p>
                            Give your customers a more flexible and easier way
                            to pay using our Xero integration, adding an
                            iwocaPay paylink automatically to your chosen Xero
                            invoices.
                        </p>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            <QuickbooksLogo />
                        </div>
                        <h3 className={styles.disabled}>
                            QuickBooks Accounting
                        </h3>
                        <p className={styles.disabled}>Coming soon...</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Plugins;
