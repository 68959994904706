import React from 'react';

import classnames from 'classnames';
import styles from './AnchorButton.module.scss';

const AnchorButton = ({ children, href, className, dataGaId }) => {
    return (
        <a
            className={classnames(className, styles.anchorButton)}
            href={href}
            data-ga-id={dataGaId}>
            {children}
        </a>
    );
};

export default AnchorButton;
