import { useEffect, useState } from 'react';

const constructUrl = (url) => {
    if (typeof window === 'undefined') {
        return url;
    }

    const isUrlAnchor = url.startsWith('#');
    if (isUrlAnchor) {
        return `${window.location.search}${url}`;
    }
    return `${url}${window.location.search}`;
};

const useQueryUrl = (url) => {
    const [queryUrl, setEligibilityUrl] = useState();

    useEffect(() => {
        setEligibilityUrl(constructUrl(url));
    }, [url]);

    return { queryUrl };
};

export default useQueryUrl;
