import React from 'react';

import AnchorButton from '../AnchorButton/AnchorButton';
import Chevron from '../../../../../../aphrodite-shared/SVGComponents/Chevron';
import styles from './AboveTheFold.module.scss';
import { Element } from 'react-scroll';
import useQueryUrl from '../../../../util/useQueryUrl.hook';
import { fetchIsLoggedIn } from '../../../../util/authHelpers';
import AboveTheFoldForm from './AboveTheFoldForm/AboveTheFoldForm';
import AboveTheFoldImage from './IWPSellersAboveTheFoldImage.png';
import ReactMarkdown from 'react-markdown';

const AboveTheFold = ({
    header,
    subHeader,
    ctaText,
    ctaUrl,
    submitInputRef
}) => {
    const { queryUrl } = useQueryUrl(ctaUrl);
    const isLoggedIn = fetchIsLoggedIn();

    return (
        <Element name="introduction" className={styles.content}>
            <div className={styles.textContent}>
                <ReactMarkdown
                    className={styles.header}
                    source={header}
                    escapeHtml={false}
                />
                <ReactMarkdown
                    className={styles.subHeader}
                    source={subHeader}
                    escapeHtml={false}
                />
                {isLoggedIn ? (
                    <AnchorButton
                        href={queryUrl}
                        className={styles.cta}
                        dataGaId="aboveTheFold___iwocapaycta">
                        {ctaText}
                        <Chevron className={styles.chevron} />
                    </AnchorButton>
                ) : (
                    <AboveTheFoldForm
                        queryUrl={queryUrl}
                        submitInputRef={submitInputRef}
                    />
                )}
            </div>
            <img
                src={AboveTheFoldImage}
                className={styles.image}
                alt="Not found."
            />
        </Element>
    );
};

export default AboveTheFold;
