import React, { useState } from 'react';

import classnames from 'classnames';

import PayNow from './PayNow/PayNow';
import PayLater from './PayLater/PayLater';

import styles from './Benefits.module.scss';
import PillTabs from './PillTabs/PillTabs';
import { Element } from 'react-scroll';

const Benefits = ({ type = 'seller', payNowImage, payLaterImage }) => {
    const [isPayNowSelected, setIsPayLaterSelected] = useState(false);

    return (
        <Element name={'benefits-' + type} className={styles.container}>
            <PillTabs
                type={type}
                isFree={isPayNowSelected}
                onChangeSelection={setIsPayLaterSelected}
            />
            <div
                data-testid="benefits"
                className={classnames(styles.Benefits, {
                    [styles.payNowDisplayed]: isPayNowSelected
                })}>
                <PayLater type={type} image={payLaterImage} />
                <PayNow type={type} image={payNowImage} />
            </div>
        </Element>
    );
};

export default Benefits;
