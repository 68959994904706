import React, { useRef } from 'react';

import ReactMarkdown from 'react-markdown';
import GatsbyImage from 'gatsby-image';
import Slider from 'react-slick';
import { Element } from 'react-scroll';

import Chevron from 'aphrodite-shared/SVGComponents/Chevron';

import styles from './Testimonials.module.scss';
import TrustpilotStar from './TrustpilotStar';

const Testimonials = ({ testimonials }) => {
    const sliderSettings = {
        autoplaySpeed: 6000,
        autoplay: true,
        slidesToShow: getNumberOfSlidesToShow(),
        arrows: false
    };

    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <Element name="reviews" className={styles.testimonials}>
            <div className={styles.shadeLeftRectangle} />
            <Slider
                ref={sliderRef}
                {...sliderSettings}
                className={styles.slider}>
                {testimonials.map((testimonial) => (
                    <div key={testimonial.name} className={styles.testimonial}>
                        <div className={styles.bubbleWrapper}>
                            <div className={styles.bubble}>
                                <GatsbyImage
                                    fluid={testimonial.bubble.fluid}
                                    className={styles.companyImage}
                                    imgStyle={{ objectFit: 'scale-down' }}
                                />
                            </div>
                        </div>
                        <div className={styles.content}>
                            <h5 className={styles.companyOwner}>
                                {testimonial.name}
                            </h5>
                            <h6 className={styles.companyName}>
                                {testimonial.companyName}
                            </h6>
                            <ReactMarkdown
                                source={testimonial.longTestimony.longTestimony}
                                escapeHtml={false}
                            />
                            <TrustpilotStars
                                rating={testimonial.trustpilotRating}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
            <div className={styles.buttonsContainer}>
                <button className={styles.slickButton} onClick={prev}>
                    <Chevron
                        className={styles.prevChevron}
                        title="Previous slide"
                    />
                </button>
                <button className={styles.slickButton} onClick={next}>
                    <Chevron
                        className={styles.nextChevron}
                        title="Next slide"
                    />
                </button>
            </div>
            <div className={styles.shadeRightRectangle} />
        </Element>
    );
};

const TrustpilotStars = ({ rating }) => {
    const stars = [];

    for (let i = 0; i < rating; i++) {
        stars.push(<TrustpilotStar key={i} title="1 trustpilot star" />);
    }

    return <div className={styles.trustPilotStars}>{stars}</div>;
};

const getNumberOfSlidesToShow = () => {
    const screenSize = typeof window !== 'undefined' && window.innerWidth;

    if (screenSize > 1382) {
        return 3;
    } else if (screenSize > 800) {
        return 2;
    } else {
        return 1;
    }
};

export default Testimonials;
