import React from 'react';

const Instagram = ({ ...props }) => {
    return (
        <svg
            {...props}
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M12.5859 2.162C15.7899 2.162 16.1699 2.174 17.4349 2.232C18.8009 2.294 20.0679 2.568 21.0429 3.543C22.0179 4.518 22.2919 5.785 22.3539 7.151C22.4119 8.416 22.4239 8.796 22.4239 12C22.4239 15.204 22.4119 15.584 22.3539 16.849C22.2919 18.215 22.0179 19.482 21.0429 20.457C20.0679 21.432 18.8009 21.706 17.4349 21.768C16.1699 21.826 15.7899 21.838 12.5859 21.838C9.38194 21.838 9.00194 21.826 7.73694 21.768C6.37094 21.706 5.10394 21.432 4.12894 20.457C3.15394 19.482 2.87994 18.215 2.81794 16.849C2.75994 15.584 2.74794 15.204 2.74794 12C2.74794 8.796 2.75994 8.416 2.81794 7.151C2.87994 5.785 3.15394 4.518 4.12894 3.543C5.10394 2.568 6.37094 2.294 7.73694 2.232C9.00194 2.174 9.38194 2.162 12.5859 2.162ZM12.5859 0C9.32694 0 8.91794 0.014 7.63794 0.072C5.68794 0.161 3.97494 0.639 2.59994 2.014C1.22494 3.389 0.746937 5.102 0.657937 7.052C0.599937 8.332 0.585938 8.741 0.585938 12C0.585938 15.259 0.599937 15.668 0.657937 16.948C0.746937 18.898 1.22494 20.611 2.59994 21.986C3.97494 23.361 5.68794 23.839 7.63794 23.928C8.91794 23.986 9.32694 24 12.5859 24C15.8449 24 16.2539 23.986 17.5339 23.928C19.4839 23.839 21.1969 23.361 22.5719 21.986C23.9469 20.611 24.4249 18.898 24.5139 16.948C24.5719 15.668 24.5859 15.259 24.5859 12C24.5859 8.741 24.5719 8.332 24.5139 7.052C24.4249 5.102 23.9469 3.389 22.5719 2.014C21.1969 0.639 19.4839 0.161 17.5339 0.072C16.2539 0.014 15.8449 0 12.5859 0Z"
                    fill="#143B6B"
                />
                <path
                    d="M12.5858 5.83789C9.18283 5.83789 6.42383 8.59689 6.42383 11.9999C6.42383 15.4029 9.18283 18.1619 12.5858 18.1619C15.9888 18.1619 18.7478 15.4029 18.7478 11.9999C18.7478 8.59689 15.9888 5.83789 12.5858 5.83789ZM12.5858 15.9999C10.3768 15.9999 8.58583 14.2089 8.58583 11.9999C8.58583 9.79089 10.3768 7.99989 12.5858 7.99989C14.7948 7.99989 16.5858 9.79089 16.5858 11.9999C16.5858 14.2089 14.7948 15.9999 12.5858 15.9999Z"
                    fill="#143B6B"
                />
                <path
                    d="M18.9927 7.0343C19.788 7.0343 20.4327 6.38959 20.4327 5.5943C20.4327 4.79901 19.788 4.1543 18.9927 4.1543C18.1974 4.1543 17.5527 4.79901 17.5527 5.5943C17.5527 6.38959 18.1974 7.0343 18.9927 7.0343Z"
                    fill="#143B6B"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.585938)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Instagram;
