import React from 'react';

const IwocaSimpleIcon = ({ ...props }) => {
    return (
        <svg
            {...props}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.216 32H1.79743C0.803393 32 0 31.1966 0 30.2026V1.79746C0 0.803414 0.803393 0 1.79743 0H30.2024C31.1964 0 31.9998 0.803414 31.9998 1.79746V30.2026C32.0134 31.1966 31.21 32 30.216 32ZM3.60849 28.3915H28.4186V3.58128H3.60849V28.3915Z"
                fill="#FB534A"
            />
            <path
                d="M23.1736 15.4398V10.2978C23.1736 10.0387 22.8559 9.86138 22.555 9.95686L19.0273 11.1571C18.8601 11.2117 18.7598 11.348 18.7598 11.4981V20.5C18.7598 20.65 18.8601 20.7727 19.0273 20.8409L22.555 22.0412C22.8559 22.1367 23.1736 21.973 23.1736 21.7002V15.4398Z"
                fill="#FB534A"
            />
            <path
                d="M13.2419 11.4981V20.5C13.2419 20.65 13.1416 20.7727 12.9744 20.8409L9.44673 22.0412C9.14579 22.1367 8.82812 21.973 8.82812 21.7002V10.2978C8.82812 10.0387 9.14579 9.86138 9.44673 9.95686L12.9744 11.1571C13.1249 11.2117 13.2419 11.348 13.2419 11.4981Z"
                fill="#FB534A"
            />
        </svg>
    );
};

export default IwocaSimpleIcon;
