import { defineMessages } from 'react-intl';

export default defineMessages({
    please_enter_a_valid_email_address: {
        id: '_.above_the_fold__please_enter_a_valid_email_address',
        defaultMessage: 'Please enter a valid email address'
    },
    email_already_registered: {
        id: '_.above_the_fold__email_already_registered',
        defaultMessage: 'This email is already registered.'
    },
    email_placeholder: {
        id: '_.above_the_fold__email_placeholder',
        defaultMessage: 'Your business email'
    },
    marketing_sign_up_action: {
        id: '_.above_the_fold__marketing_sign_up_action',
        defaultMessage: 'Sign up for small business news'
    },
    submitting: {
        id: '_.above_the_fold__submitting',
        defaultMessage: 'Submitting'
    }
});
