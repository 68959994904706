import React from 'react';

const XeroLogo = ({ width = '66', height = '66', className }) => (
    <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 72 73"
        xmlns="http://www.w3.org/2000/svg"
        fill="none">
        <rect
            width="71.739"
            height="71.761"
            x=".58"
            y=".841"
            fill="#1AB4D7"
            rx="35.87"
        />
        <path
            fill="#fff"
            fill-rule="evenodd"
            d="m19.567 37.784 5.357-5.534c.178-.187.277-.434.277-.696 0-.546-.431-.99-.96-.99-.259 0-.5.105-.683.296l-5.352 5.513-5.376-5.522a.945.945 0 0 0-.678-.286c-.529 0-.96.443-.96.988 0 .267.103.516.288.704l5.357 5.52-5.354 5.527a1 1 0 0 0-.29.709c0 .546.43.988.96.988a.938.938 0 0 0 .677-.288l5.368-5.525 5.347 5.504c.19.202.434.31.695.31.53 0 .96-.443.96-.99a.997.997 0 0 0-.28-.697l-5.353-5.53Zm31.952 0c0 .936.666 1.698 1.487 1.698.818 0 1.485-.762 1.485-1.698 0-.937-.667-1.699-1.485-1.699-.82 0-1.487.762-1.487 1.699Zm1.91-5.18c-2.773 0-5.027 2.324-5.027 5.18s2.254 5.18 5.026 5.18c2.77 0 5.026-2.324 5.026-5.18s-2.256-5.18-5.026-5.18Zm0 12.398c-3.863 0-7.004-3.238-7.004-7.218s3.142-7.22 7.003-7.22c3.862 0 7.005 3.24 7.005 7.22 0 3.98-3.143 7.218-7.005 7.218Zm-7.952-14.436-.29-.001c-.874 0-1.716.292-2.42.866-.092-.45-.471-.788-.923-.788-.52 0-.936.442-.937.993l.003 12.368c.001.55.425.998.945.998s.944-.448.946-1v-7.604c0-2.535.219-3.558 2.268-3.83.186-.024.387-.02.396-.02.562-.02.96-.43.96-.98 0-.553-.425-1.002-.948-1.002Zm-18.183 5.93-.003.082h9.912c-.53-2.3-2.55-4.014-4.964-4.014-2.384 0-4.386 1.672-4.945 3.931Zm8.752-4.822c1.638 1.06 2.714 2.71 3.13 4.718.204 1.137-.538 2.132-1.916 2.161l-10.053.002c.002.047.007.099.013.152.05.285.122.561.215.827.534 1.427 2.033 3.426 4.834 3.458a4.982 4.982 0 0 0 2.367-.611 5.555 5.555 0 0 0 1.393-1.148c.124-.151.236-.302.337-.45.47-.614 1.067-.501 1.422-.223.422.33.493 1.022.11 1.508a.514.514 0 0 1-.014.016l-.02.023c-.553.708-1.124 1.3-1.807 1.75a6.874 6.874 0 0 1-2.11.92 6.58 6.58 0 0 1-2.542.172c-2.872-.36-5.281-2.528-5.994-5.392a7.258 7.258 0 0 1-.21-1.741c0-2.312 1.089-4.556 2.975-5.929 2.313-1.68 5.476-1.767 7.87-.213Z"
            clip-rule="evenodd"
        />
    </svg>
);

export default XeroLogo;
