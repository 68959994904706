import React from 'react';

import classnames from 'classnames';
import styles from './Features.module.scss';
import { Element } from 'react-scroll';
import { useState } from 'react';
import CheckMark from '../../../../SVGComponents/CheckMarkIconWhite';
import IwocaIcon from '../../../../SVGComponents/IwocaPayIconWhite';
import IwocaFullLogo from '../../../../SVGComponents/IwocaPayFullLogoWhite';
import PayPalIcon from '../../../../SVGComponents/PayPalLogo';
import StopIcon from '../../../../SVGComponents/StopIcon';
import WedgeCoral from './WedgeCoral';

import ThreeStars from './ThreeBlueStars';
import FiveStars from './FiveWhiteStars';

const Features = () => {
    const [isFreeForYou, setIsFree] = useState(true);
    const onChangeSelection = (isFree) => setIsFree(isFree);

    return (
        <Element name="whyIwocaPay" className={styles.features}>
            <div className={styles.headingContent}>
                <h2>Get paid on your terms, everytime</h2>
                <p>
                    Give your business customers more choice <i>and</i> keep
                    control of costs. Take all your payments through iwocaPay
                    for free or stand out from the competition and give your
                    business customers interest free extended terms - for all of
                    them, or just a special few.
                </p>
            </div>
            <div>
                <div className={styles.wedgeWrapper}>
                    <WedgeCoral className={styles.wedge} />
                </div>
                <div className={styles.container}>
                    <FeaturesTable
                        isFreeForYou={isFreeForYou}
                        onChangeSelection={onChangeSelection}
                    />
                    <MobileFeaturesList
                        isFreeForYou={isFreeForYou}
                        onChangeSelection={onChangeSelection}
                    />
                    <p className={styles.subtitle}>
                        It’s always free for you and your customer if they Pay
                        Now.
                    </p>
                </div>
            </div>
        </Element>
    );
};

const PillButtons = (props) => {
    const { isFreeForYou: isFree, onChangeSelection } = props;

    return (
        <div className={styles.pillButtons}>
            <div
                onClick={() => onChangeSelection(isFree || !isFree)}
                className={classnames(styles.link, {
                    [styles.selected]: isFree
                })}>
                Free for you
            </div>
            <div
                onClick={() => onChangeSelection(!isFree && isFree)}
                className={classnames(styles.link, {
                    [styles.selected]: !isFree
                })}>
                Free for your customers
            </div>
            <div className={styles.pillBackground}></div>
            <span className={styles.pill}></span>
        </div>
    );
};

const PaymentTermsRow = (props) => {
    const { highlightColumnClasses, isFreeForYou } = props;

    return (
        <>
            <td className={styles.featureColumn}>
                <p>Built in payment terms</p>
                <span>For business customers</span>
            </td>
            <td>
                <StopIcon />
            </td>
            <td>
                <StopIcon />
            </td>
            <td>
                <StopIcon />
            </td>
            <td className={highlightColumnClasses}>
                <CheckMark />
                <p>90 days{!isFreeForYou && ', interest free'}</p>
            </td>
        </>
    );
};

const CheckoutExperienceRow = (props) => {
    const { highlightColumnClasses } = props;

    return (
        <>
            <td className={styles.featureColumn}>
                <p>Checkout experience</p>
                <span>Clicks to pay</span>
            </td>
            <td>
                <p className={styles.enlargedText}>23</p>
            </td>
            <td>
                <p className={styles.enlargedText}>20</p>
            </td>
            <td>
                <p className={styles.enlargedText}>10</p>
            </td>
            <td className={highlightColumnClasses}>
                <p className={styles.enlargedText}>
                    3{' '}
                    <span style={{ fontWeight: 500, fontSize: 14 }}>
                        clicks
                    </span>
                </p>
            </td>
        </>
    );
};

const TrustPilotRow = (props) => {
    const { highlightColumnClasses } = props;

    return (
        <>
            <td className={styles.featureColumn}>
                <p>Trustpilot rating</p>
            </td>
            <td>
                <p className={styles.enlargedText}>NA</p>
            </td>
            <td>
                <p className={styles.enlargedText}>NA</p>
            </td>
            <td>
                <ThreeStars />
            </td>
            <td className={highlightColumnClasses}>
                <FiveStars />
            </td>
        </>
    );
};

const DaysUntilFunds = (props) => {
    const { highlightColumnClasses } = props;

    return (
        <>
            <td className={styles.featureColumn}>
                <p>Days to receive funds</p>
                <span>Up to</span>
            </td>
            <td>
                <p className={styles.enlargedText}>3</p>
                <span className={styles.miniText}>business days</span>
            </td>
            <td>
                <p className={styles.enlargedText}>3</p>
                <span className={styles.miniText}>business days</span>
            </td>
            <td>
                <p className={styles.enlargedText}>21</p>
                <span className={styles.miniText}>days</span>
            </td>
            <td className={highlightColumnClasses}>
                <p>always instant</p>
            </td>
        </>
    );
};

const TransactionFees = (props) => {
    const { highlightColumnClasses, isFreeForYou } = props;

    return (
        <>
            <td className={styles.featureColumn}>
                <p>Transaction fees</p>
            </td>
            <td>
                <span>free</span>
            </td>
            <td className={styles.feesText}>
                <p>1 to 3.3% + 3p</p>
                <span>
                    <i>and £5 to 30 pm</i>
                </span>
            </td>
            <td className={styles.feesText}>
                <p>2.9 to 3.4%</p>
                <p>+ 30p</p>
            </td>
            <td className={highlightColumnClasses}>
                <p className={styles.enlargedText}>
                    {isFreeForYou ? 'free' : '3%'}
                </p>
            </td>
        </>
    );
};

const FeaturesTable = (props) => {
    const { isFreeForYou, onChangeSelection } = props;

    const highlightColumnClasses = classnames(styles.featureHighlightCol, {
        [styles.freeForYou]: isFreeForYou
    });

    return (
        <div className={styles.featuresTableWrapper}>
            <PillButtons
                isFreeForYou={isFreeForYou}
                onChangeSelection={onChangeSelection}
            />
            <table className={styles.featuresTable}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Bank transfers</th>
                        <th>Cards</th>
                        <th>
                            <PayPalIcon style={{ marginTop: '.5rem' }} />
                        </th>
                        <th className={highlightColumnClasses}>
                            <IwocaIcon />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <PaymentTermsRow
                            highlightColumnClasses={highlightColumnClasses}
                            isFreeForYou={isFreeForYou}
                        />
                    </tr>
                    <tr>
                        <CheckoutExperienceRow
                            highlightColumnClasses={highlightColumnClasses}
                            isFreeForYou={isFreeForYou}
                        />
                    </tr>
                    <tr>
                        <TrustPilotRow
                            highlightColumnClasses={highlightColumnClasses}
                            isFreeForYou={isFreeForYou}
                        />
                    </tr>
                    <tr>
                        <DaysUntilFunds
                            highlightColumnClasses={highlightColumnClasses}
                        />
                    </tr>
                    <tr>
                        <TransactionFees
                            highlightColumnClasses={highlightColumnClasses}
                            isFreeForYou={isFreeForYou}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const MobileFeaturesList = (props) => {
    const { isFreeForYou } = props;

    return (
        <div
            className={classnames(styles.featuresList, {
                [styles.freeForYou]: isFreeForYou
            })}>
            <IwocaFullLogo className={styles.iwocaLogo} />
            <ul>
                <li>
                    <CheckMark />
                    <div className={styles.textContainer}>
                        <p>90 days{!isFreeForYou && ', interest free'}</p>
                        <span>Offer Pay Later, you get paid up front. </span>
                    </div>
                </li>
                <li>
                    <CheckMark />
                    <div className={styles.textContainer}>
                        <p>3 clicks</p>
                        <span>
                            Payments via online banking on mobile or desktop.
                        </span>
                    </div>
                </li>
                <li>
                    <CheckMark />
                    <div className={styles.textContainer}>
                        <p>4.5 star Trustpilot review</p>
                        <div className={styles.starsList}>
                            <FiveStars />
                        </div>
                    </div>
                </li>
                <li>
                    <CheckMark />
                    <div className={styles.textContainer}>
                        <p>always instant</p>
                        <span>
                            You get the funds to your bank account within
                            seconds.
                        </span>
                    </div>
                </li>
                <li>
                    <CheckMark />
                    <div className={styles.textContainer}>
                        <p>{isFreeForYou ? 'free' : '3%'}</p>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Features;
