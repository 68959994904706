import React from 'react';

import classnames from 'classnames';
import GatsbyImage from 'gatsby-image';

import styles from './PayNow.module.scss';

const SellerPayNowContent = () => (
    <>
        <h3>Instant payouts.</h3>
        <p>
            Powered by Faster Payments - you get 100% of your funds in your
            account as soon as they checkout.{' '}
        </p>
        <h3>A smart alternative to card payments.</h3>
        <p>
            No fees, no interest, no failed transactions, just instant secure
            payments from their bank to yours.{' '}
        </p>
        <h3>Always free for everyone. </h3>
        <p>
            It’s always free for you and your customer to settle up with Pay
            Now.
        </p>
    </>
);

const CustomerPayNowContent = () => (
    <>
        <h3>Let customers pay wherever they are.</h3>
        <p>
            All they need is an internet connection and your paylink. Settle in
            a few clicks or by scanning a QR code.
        </p>
        <h3>Simple, streamlined and secure.</h3>
        <p>
            We pre-fill the details and take them straight to their bank account
            to make paying seamless.
        </p>
        <h3>Upgrade your payment experience.</h3>
        <p>Make settling up feel just as slick as everything else you do.</p>
    </>
);

const PayNow = ({ type, image }) => (
    <div className={styles.payNowContainer}>
        <div className={styles.payNow}>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.textBlock}>
                        {type === 'seller' ? (
                            <SellerPayNowContent />
                        ) : (
                            <CustomerPayNowContent />
                        )}
                    </div>

                    <div
                        className={classnames(
                            styles.imageContainer,
                            styles[type]
                        )}>
                        <GatsbyImage
                            fluid={image}
                            className={classnames(styles.image, styles[type])}
                            imgStyle={{ objectFit: 'scale-down' }}
                            alt="Laptop"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PayNow;
