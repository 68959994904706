import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width={12}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="m10.5 1.5-9 9m3-9h6v6"
            stroke="#256AC0"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgComponent;
