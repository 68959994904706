import React from 'react';

const QuickbooksLogo = ({ width = '66', height = '65' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 66 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.35">
            <path
                d="M32.6671 65C50.6164 65 65.1671 50.4493 65.1671 32.5C65.1671 14.5508 50.6164 4.00543e-05 32.6671 4.00543e-05C14.7179 4.00543e-05 0.167114 14.5508 0.167114 32.5C0.167114 50.4493 14.7179 65
32.6671 65Z"
                fill="#2CA01C"
            />
            <path
                d="M9.19531 32.5C9.19531 39.5056 14.8286 45.1389 21.8342 45.1389H23.6398V40.4445H21.8342C17.4286 40.4445 13.8898 36.9056 13.8898 32.5C13.8898 28.0945 17.4286 24.5556 21.8342
24.5556H26.1675V49.1111C26.1675 51.7111 28.262 53.8056 30.862 53.8056V19.8611H21.8342C14.8286 19.8611 9.19531 25.4945 9.19531 32.5ZM43.5009 19.8611H41.6953V24.5556H43.5009C47.9064 24.5556 51.4453
28.0945 51.4453 32.5C51.4453 36.9056 47.9064 40.4445 43.5009 40.4445H39.1675V15.8889C39.1675 13.2889 37.0731 11.1945 34.4731 11.1945V45.1389H43.5009C50.5064 45.1389 56.1398 39.5056 56.1398
32.5C56.1398 25.4945 50.5064 19.8611 43.5009 19.8611Z"
                fill="white"
            />
        </g>
    </svg>
);

export default QuickbooksLogo;
