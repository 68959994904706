import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width={82}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M7.36 0 5.085 4.582 0 5.312 3.68 8.88l-.87 5.034 4.55-2.375 4.55 2.375-.87-5.034 3.68-3.563-5.085-.735L7.36 0Zm18.72 0-2.274 4.582-5.085.73L22.4 8.88l-.87 5.034 4.55-2.375 4.55 2.375-.87-5.034 3.68-3.563-5.085-.735L26.08 0Zm18.721 0-2.275 4.582-5.085.73 3.68 3.568-.87 5.034 4.55-2.375 4.55 2.375-.87-5.034 3.68-3.563-5.085-.735L44.801 0ZM63.52 0l-2.275 4.582-5.085.73 3.68 3.568-.87 5.034 4.55-2.375 4.55 2.375-.87-5.034 3.68-3.563-5.085-.735L63.52 0Z"
            fill="#fff"
        />
        <g clipPath="url(#prefix__a)">
            <path
                d="m82.24 0-2.274 4.582-5.085.73 3.68 3.568-.87 5.034 4.55-2.375 4.55 2.375-.87-5.034 3.68-3.563-5.085-.735L82.24 0Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="prefix__a">
                <path fill="#fff" transform="translate(75)" d="M0 0h7v14H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SvgComponent;
