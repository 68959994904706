import React from 'react';

import classnames from 'classnames';
import { Link } from 'react-scroll';

import styles from './Navigator.module.scss';

const Navigator = ({ className, onLinkClick }) => {
    return (
        <div className={classnames(className, styles.navigator)}>
            <ul className={styles.navigatorLinks}>
                <li>
                    <NavigatorLink
                        to="whyIwocaPay"
                        index="1"
                        onLinkClick={onLinkClick}>
                        Why iwocaPay?
                    </NavigatorLink>
                </li>
                <li>
                    <NavigatorLink
                        to="benefits-seller"
                        index="2"
                        onLinkClick={onLinkClick}>
                        How it works
                    </NavigatorLink>
                </li>
                <li>
                    <NavigatorLink
                        to="reviews"
                        index="3"
                        onLinkClick={onLinkClick}>
                        Case studies
                    </NavigatorLink>
                </li>
            </ul>
        </div>
    );
};

const NavigatorLink = ({ children, onLinkClick, ...props }) => {
    return (
        <div className={styles.navigatorContainer}>
            <Link
                {...props}
                spy
                activeClass={styles.activeNavigatorLink}
                onClick={onLinkClick}
                className={styles.navigatorLink}>
                {children}
            </Link>
            <div className={styles.navigatorLinkHighlighter} />
        </div>
    );
};

export default Navigator;
