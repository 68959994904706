import React from 'react';

const Facebook = ({ ...props }) => {
    return (
        <svg
            {...props}
            width="15"
            height="22"
            viewBox="0 0 15 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.61747 22L4.58594 12H0.585938V8H4.58594V5.5C4.58594 1.7886 6.88426 0 10.1951 0C11.781 0 13.144 0.11807 13.5412 0.17085V4.04948L11.245 4.05052C9.44439 4.05052 9.09575 4.90614 9.09575 6.16171V8H14.3359L12.3359 12H9.09575V22H4.61747Z"
                fill="#143B6B"
            />
        </svg>
    );
};

export default Facebook;
