import React, { useEffect, useState, useRef } from 'react';

import Chevron from 'aphrodite-shared/SVGComponents/Chevron';
import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';

import IwocaSimpleIcon from '../../../../../SVGComponents/IwocaSimpleIcon';
import AnchorButton from '../../AnchorButton/AnchorButton';
import Navigator from '../../Navigator/Navigator';
import styles from './MobileNavBar.module.scss';
import useNavbar from '../useNavbar.hook.js';
import NavHidingCTAButton from '../NavCTAButton/NavCTAButton';

const MobileNavBar = ({ menuItems, partnerLogo, setSubmitInputFocus }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { ctaProps, isLoggedIn } = useNavbar();

    const navBarRef = useRef(null);

    useEffect(() => {
        const handleTouchStart = (e) => {
            if (
                e.target instanceof HTMLElement &&
                navBarRef.current &&
                !navBarRef.current.contains(e.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('touchstart', handleTouchStart);

        return () =>
            document.removeEventListener('touchstart', handleTouchStart);
    }, [isDropdownOpen]);

    return (
        <div
            ref={navBarRef}
            className={classnames(styles.NavBar, {
                [styles.partnerNavbar]: !!partnerLogo
            })}>
            {partnerLogo ? (
                <GatsbyImage
                    fluid={partnerLogo.fluid}
                    className={styles.partnershipIcon}
                    imgStyle={{
                        objectFit: 'contain'
                    }}
                />
            ) : (
                <div className={styles.iwocaPayIconContainer}>
                    <IwocaSimpleIcon />
                </div>
            )}
            <div className={styles.rightPane}>
                {isLoggedIn ? (
                    <AnchorButton
                        className={styles.redirectCTA}
                        href={ctaProps.href}
                        dataGaId={ctaProps.dataGaId}>
                        {ctaProps.text}
                    </AnchorButton>
                ) : (
                    <NavHidingCTAButton
                        dataGaId={ctaProps.dataGaId}
                        setSubmitInputFocus={setSubmitInputFocus}
                        hiddenDepth={250}
                    />
                )}
                <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className={styles.menuItemButton}>
                    <span>
                        Menu
                        <Chevron
                            title="menu open indicator"
                            className={classnames(styles.chevron, {
                                [styles.chevronActive]: isDropdownOpen
                            })}
                        />
                    </span>
                </button>
                <div
                    className={classnames(styles.dropdownWrapper, {
                        [styles.isDropdownOpen]: isDropdownOpen
                    })}>
                    {!isLoggedIn && (
                        <a
                            href="/pay/dashboard/"
                            className={styles.loginButton}>
                            Login
                        </a>
                    )}
                    <Navigator
                        className={styles.navigator}
                        onLinkClick={() => setIsDropdownOpen(false)}
                    />
                    {menuItems.map(({ name, item }) => (
                        <MenuItem key={name} name={name} subMenuItems={item} />
                    ))}
                    <div className={styles.contactDetails}>
                        <a href="tel:02037780549">0203 778 0549</a>
                        <a href="mailto:pay@iwoca.co.uk">pay@iwoca.co.uk</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ name, subMenuItems }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.menuItemButton}>
                <span>
                    {name}
                    <Chevron
                        className={classnames(styles.chevron, {
                            [styles.chevronActive]: isMenuOpen
                        })}
                    />
                </span>
            </button>
            <div
                className={classnames(styles.items, {
                    [styles.isMenuOpen]: isMenuOpen
                })}>
                <div className={styles.menuItemContent}>
                    {subMenuItems.map(({ title, url }) => (
                        <a key={title} href={url} className={styles.itemLink}>
                            {title}
                        </a>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MobileNavBar;
