import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width={1107}
        height={918}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            opacity={0.2}
            d="m610.628 5.924-2.464-1.428c-15.921-9.181-38.657-3.514-48.256 13.078C425.664 249.56 135.357 333.806-87.243 205.406c-14.645-8.402-29.301-16.917-45.374-26.197-9.52-5.496-15.729-10.324-25.161-8.15-9.432 2.175-17.615 8.272-22.586 16.87l-296.532 512.412c-9.258 15.986-4.854 36.091 9.947 45.792 16.253 10.547 32.831 20.734 49.334 30.241C1.323 1018.03 522.082 940.079 876.004 620.45c40.767-33.92 79.873-74.47 117.188-122.283 25.008-30.159 42.538-55.881 42.538-55.881s24.88-40.929 29.34-48.66c.9-1.546 3.43-6.077 7.85-13.586 10.08-17.139 19.92-34.911 29.31-52.821 8.51-16.395 3.28-36.263-11.98-45.208L610.752 5.996l-.124-.072Z"
            fill="#BFD4E0"
        />
    </svg>
);

export default SvgComponent;
