import React from 'react';

import GatsbyImage from 'gatsby-image';
import classnames from 'classnames';

import Chevron from 'aphrodite-shared/SVGComponents/Chevron';
import IwocaPayIcon from '../../../../../SVGComponents/iwocaPayIcon';
import LockIcon from '../../../../../SVGComponents/LockIcon';
import AnchorButton from '../../AnchorButton/AnchorButton';
import styles from './DesktopNavBar.module.scss';
import useNavbar from '../useNavbar.hook.js';
import NavHidingCTAButton from '../NavCTAButton/NavCTAButton';

const DesktopNavBar = ({ menuItems, partnerLogo, setSubmitInputFocus }) => {
    const { ctaProps, isLoggedIn } = useNavbar();

    return (
        <div
            className={classnames(styles.NavBar, {
                [styles.partnerNavbar]: !!partnerLogo
            })}>
            <div className={styles.leftPane}>
                {partnerLogo ? (
                    <GatsbyImage
                        fluid={partnerLogo.fluid}
                        className={styles.partnershipIcon}
                        imgStyle={{
                            objectFit: 'contain'
                        }}
                    />
                ) : (
                    <div className={styles.iwocaPayIconContainer}>
                        <IwocaPayIcon className={styles.iwocaPayIcon} />
                    </div>
                )}
                <div className={styles.menuItems}>
                    {menuItems.map(({ name, item }) => (
                        <MenuItem key={name} name={name} subMenuItems={item} />
                    ))}
                </div>
            </div>
            <div className={styles.rightPane}>
                {!isLoggedIn && (
                    <>
                        <NavHidingCTAButton
                            dataGaId={ctaProps.dataGaId}
                            setSubmitInputFocus={setSubmitInputFocus}
                        />
                        <a
                            href="/pay/dashboard/"
                            className={styles.loginButton}>
                            <span>Log in</span>{' '}
                            <LockIcon className={styles.lockIcon} />
                        </a>
                    </>
                )}
                {isLoggedIn && (
                    <AnchorButton
                        href={ctaProps.href}
                        dataGaId={ctaProps.dataGaId}>
                        {ctaProps.text}
                    </AnchorButton>
                )}
                <div className={styles.contactInfo}>
                    <div className={styles.contactText}>
                        <div className={styles.circle} />
                        <span>We're free to chat</span>
                    </div>
                    <a
                        className={styles.contactPhoneNumber}
                        href="tel:02037780549">
                        0203 778 0549
                    </a>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({ name, subMenuItems }) => {
    return (
        <button className={styles.menuItemButton}>
            <span>
                {name}
                <Chevron className={styles.chevron} />
            </span>
            <div className={styles.contentWrapper}>
                <div className={styles.menuItemContent}>
                    <div className={styles.arrow} />
                    <div className={styles.items}>
                        {subMenuItems.map(({ title, url }) => (
                            <a
                                key={title}
                                className={styles.menuItemLink}
                                href={url}>
                                {title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </button>
    );
};

export default DesktopNavBar;
