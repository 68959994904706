import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M1.5 12a10.5 10.5 0 1 0 21 0 10.5 10.5 0 0 0-21 0Zm17.363 5.813L6.188 5.137a9 9 0 0 1 12.675 12.675ZM6.18 18.87A9 9 0 0 1 5.13 6.202L17.798 18.87a9 9 0 0 1-11.618 0Z"
            fill="#143B6B"
        />
    </svg>
);

export default SvgComponent;
