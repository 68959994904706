import * as React from 'react';

const SvgComponent = (props) => (
    <svg
        width={26}
        height={26}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="m11.375 17.399-4.063-4.063 1.149-1.149 2.914 2.914 6.163-6.164 1.15 1.15-7.313 7.312Z"
            fill="#fff"
        />
        <path
            d="M13 1.625a11.375 11.375 0 1 0 0 22.75 11.375 11.375 0 0 0 0-22.75Zm0 21.125a9.75 9.75 0 1 1 0-19.499 9.75 9.75 0 0 1 0 19.499Z"
            fill="#fff"
        />
    </svg>
);

export default SvgComponent;
