import React from 'react';

import Facebook from './SocialMediaIcons/Facebook';
import Instagram from './SocialMediaIcons/Instagram';
import LinkedIn from './SocialMediaIcons/LinkedIn';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <SocialMediaLinks />
            <div className={styles.pageLinks}>
                <a className={styles.pageLink} href="/privacy-policy/">
                    Privacy policy
                </a>
                <a className={styles.pageLink} href="/terms-of-use/">
                    Terms of use
                </a>
                <a
                    className={styles.pageLink}
                    href="https://support.iwoca.co.uk/en/collections/2145896-what-s-iwocapay">
                    FAQ
                </a>
                <a className={styles.pageLink} href="/use-of-cookies/">
                    Use of cookies
                </a>
                <a className={styles.pageLink} href="/complaints/">
                    Complaints policy
                </a>
            </div>
            <div className={styles.divider} />
            <div className={styles.address}>
                iwoca Ltd, 3rd Floor 101 New Cavendish Street London, W1W 6XH
            </div>
            <div className={styles.legalAgreement}>
                iwoca Limited is authorised and regulated by the Financial
                Conduct Authority (reference number: 723378) in relation to
                regulated credit agreements and are a registered account
                information service provider (reference number: 791804). We're
                also registered with the Information Commissioners Office in
                relation to the processing of personal information (registration
                number: Z3007540). iwoca Limited is incorporated in England and
                Wales (company number: 07798925). Our registered office is 10
                Queen Street Place, London, EC4R 1AG.
            </div>
        </div>
    );
};

const SocialMediaLinks = () => {
    return (
        <div className={styles.socialMediaIcons}>
            <a
                href="https://www.instagram.com/iwoca/"
                target="_blank"
                rel="noopener noreferrer">
                <Instagram className={styles.socialMediaIcon} />
            </a>
            <a
                href="https://www.facebook.com/sharer/sharer.php?u=www.iwoca.co.uk/news/mental-health-small-business-owners/"
                target="_blank"
                rel="noopener noreferrer">
                <Facebook className={styles.socialMediaIcon} />
            </a>

            <a
                href="https://www.linkedin.com/shareArticle?mini=true&url=www.iwoca.co.uk/news/mental-health-small-business-owners/"
                target="_blank"
                rel="noopener noreferrer">
                <LinkedIn className={styles.socialMediaIcon} />
            </a>
        </div>
    );
};

export default Footer;
