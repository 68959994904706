import { buildLendingAPI } from '../../../../api/lendingApiHelper.js';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import yup from 'yup';
import {
    serialiseStateWithoutProduct,
    validateEmailUniqueness
} from '../../../../components/Modals/ApplyModalHelpers';

import { rawIntl } from 'aphrodite-shared/IwIntl/IwIntlProvider';
import messages from './AboveTheFoldForm/AboveTheFoldForm.messages';
import { getRuntimeEnvironment } from '../../../../util/getRunTimeEnvironment';

async function lendingAPIPostState(signUpData) {
    const PRODUCTION_SELLER_REGISTRATION_TOKEN =
        'e9efe6f1d9896091450e4c7ef47a65d148296abd';
    const STAGING_SELLER_REGISTRATION_TOKEN =
        '11fdcce04f91c0d8d1d16f82ba2a34c10f8554ec';

    const API_AUTH_TOKEN =
        getRuntimeEnvironment() === 'production'
            ? PRODUCTION_SELLER_REGISTRATION_TOKEN
            : STAGING_SELLER_REGISTRATION_TOKEN;

    return await buildLendingAPI(
        '/api/lending/edge/state/',
        {
            method: 'POST',
            body: JSON.stringify({
                data: signUpData
            })
        },
        {
            authorization: `Bearer ${API_AUTH_TOKEN}`
        }
    );
}

export async function submitSignupForm(signupValues) {
    try {
        const signupDataWithoutProduct = serialiseStateWithoutProduct(
            signupValues
        );
        const postStateResponse = await lendingAPIPostState(
            signupDataWithoutProduct
        );

        return postStateResponse;
    } catch (error) {
        throw new Error(error);
    }
}

export const debouncedEmailValidation = lodashDebounce(emailValidation, 750, {
    trailing: true
});

export async function emailValidation(form, event) {
    let errorMessage;
    const currentEmailInput = lodashGet(event, 'target.value');

    if (!currentEmailInput) return;
    if (currentEmailInput.length < 5) return; // Minimum email x@x.x

    // Validate if input matches email pattern
    const validEmail = await yup
        .string()
        .email()
        .isValid(currentEmailInput);

    if (!validEmail) {
        errorMessage = rawIntl.formatMessage({
            ...messages.please_enter_a_valid_email_address
        });
    }

    // If input matches email pattern, run it against the API
    if (validEmail) {
        const uniqueEmail = await validateEmailUniqueness(currentEmailInput);

        if (!uniqueEmail) {
            errorMessage = rawIntl.formatMessage({
                ...messages.email_already_registered
            });
        }
    }

    // Persist values to form
    await form.setFieldError('applicantEmailAddress', errorMessage);
}
