import React, { useRef } from 'react';
import { graphql } from 'gatsby';

import styles from './IwocapaySellers2.module.scss';
import AboveTheFold from './AboveTheFold/AboveTheFold';
import NavBar from './NavBar/NavBar';
import MetaData from '../../../components/Layout/MetaData';
import Benefits from './Benefits/Benefits';
import Footer from './Footer/Footer';
import Features from './Features/Features';
import Testimonials from './Testimonials/Testimonials';
import Navigator from './Navigator/Navigator';
import Plugins from './Plugins/Plugins';

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
};

const IwocapaySellers2 = ({ data }) => {
    const [submitInputRef, setSubmitInputFocus] = useFocus();
    const { contentfulPageIwocaPaySellers2: pageData } = data;

    const {
        url,
        titleTag,
        metaDescription,
        allowSearchEngineScraping,
        navbarLogoMobile,
        navbarLogoDesktop,
        aboveTheFoldHeading,
        aboveTheFoldSubheader,
        aboveTheFoldCta,
        benefitsPayLaterCustomer,
        benefitsPayNowCustomer,
        benefitsPayLaterSeller,
        benefitsPayNowSeller,
        navBar,
        testimonials
    } = pageData;

    return (
        <>
            <MetaData
                metaData={{
                    titleTag,
                    url,
                    metaDescription,
                    allowSearchEngineScraping
                }}
            />
            <NavBar
                menuItems={navBar.menuItems}
                mobileLogo={navbarLogoMobile}
                desktopLogo={navbarLogoDesktop}
                setSubmitInputFocus={setSubmitInputFocus}
            />
            <div className={styles.page}>
                <Navigator />
                <div className={styles.mainContent}>
                    <AboveTheFold
                        header={aboveTheFoldHeading.aboveTheFoldHeading}
                        subHeader={aboveTheFoldSubheader.aboveTheFoldSubheader}
                        ctaText={aboveTheFoldCta.buttonText}
                        ctaUrl={aboveTheFoldCta.url}
                        submitInputRef={submitInputRef}
                    />
                    <Plugins />
                    <Features />
                    <Benefits
                        type="seller"
                        payLaterImage={benefitsPayLaterSeller.fluid}
                        payNowImage={benefitsPayNowSeller.fluid}
                    />
                    <Benefits
                        type="customer"
                        payLaterImage={benefitsPayLaterCustomer.fluid}
                        payNowImage={benefitsPayNowCustomer.fluid}
                    />
                    <Testimonials testimonials={testimonials} />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default IwocapaySellers2;

export const query = graphql`
    query IwocaPaySellers2PageQuery($id: String!) {
        contentfulPageIwocaPaySellers2(id: { eq: $id }) {
            url
            titleTag
            metaDescription
            allowSearchEngineScraping
            navbarLogoMobile {
                fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            navbarLogoDesktop {
                fluid(maxWidth: 550, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            navBar {
                menuItems {
                    name
                    url
                    item {
                        title
                        url
                    }
                }
            }
            aboveTheFoldHeading {
                aboveTheFoldHeading
            }
            aboveTheFoldSubheader {
                aboveTheFoldSubheader
            }
            aboveTheFoldCta {
                buttonText
                url
            }
            benefitsPayLaterCustomer {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            benefitsPayNowCustomer {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            benefitsPayLaterSeller {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            benefitsPayNowSeller {
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            howItWorksExplanationSections {
                title
                content {
                    content
                }
            }
            benefitsPayNow {
                title
                content {
                    content
                }
            }
            benefitsPayNowImage {
                fluid(maxWidth: 700, quality: 90) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            benefitsPayLater {
                title
                content {
                    content
                }
            }
            howItWorksVideo {
                file {
                    url
                }
            }
            howItWorksMobileImage {
                fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            testimonials {
                name
                longTestimony {
                    longTestimony
                }
                companyName
                bubble {
                    fluid(maxWidth: 100, quality: 100) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                trustpilotRating
            }
            pricingHeading {
                title
                content {
                    content
                }
            }
            pricingOptionPayNow {
                title
                content {
                    content
                }
            }
            pricingOptionPayLater {
                title
                content {
                    content
                }
            }
            pricingExplanations {
                title
                content {
                    content
                }
            }
            xero {
                title
                content {
                    content
                }
            }
        }
    }
`;
