import React from 'react';

import styles from './PayLater.module.scss';
import classnames from 'classnames';
import GatsbyImage from 'gatsby-image';

import ArrowIcon from '../../../../../SVGComponents/ArrowDiagonal';

const SellerPayLaterContent = () => (
    <>
        <h3>Instant payouts.</h3>
        <p>
            As soon as your customers checkout we’ll settle 100% of your invoice
            - we’ll never ask for the money back if they don’t pay us.
        </p>
        <h3>Set up with clicks, not code.</h3>
        <p>
            Offer flexible terms and get paid by link. Set up once, send your
            pay link and you’re good to go, no coding needed.{' '}
        </p>
        <h3>Control your costs.</h3>
        <p>
            Take payments without paying a penny or let business customers
            spread the cost over 90 days interest free.
        </p>
    </>
);

const CustomerPayLaterContent = () => (
    <>
        <h3>Work with more customers.</h3>
        <p>
            Offer more flexibility around how customers pay without taking on
            the risk.
        </p>
        <div className={styles.linkTextBlock}>
            <h3>Supercharge their purchasing power.</h3>
            <p>
                Let your eligible business customers split the cost into 3 equal
                monthly repayments. You decide if it’s interest free for them or
                at a monthly interest rate.
            </p>
            <a
                className={styles.articleLink}
                href="https://www.iwoca.co.uk/customer-stories/catering%E2%80%93equipment%E2%80%93suppliers-iwocaPay/">
                How e-catering boost their e-commerce sales
                <ArrowIcon style={{ marginLeft: '5px', marginTop: '3px' }} />
            </a>
        </div>
        <h3>Offer a better service.</h3>
        <p>
            Give payment terms to suit your customers and build stronger
            relationships.
        </p>
    </>
);

const PayLater = ({ type, image }) => (
    <div className={styles.payLaterContainer}>
        <div className={styles.payLater}>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.textBlock}>
                        {type === 'seller' ? (
                            <SellerPayLaterContent />
                        ) : (
                            <CustomerPayLaterContent />
                        )}
                    </div>

                    <div
                        className={classnames(
                            styles.imageContainer,
                            styles[type]
                        )}>
                        <GatsbyImage
                            fluid={image}
                            className={classnames(styles.image, styles[type])}
                            imgStyle={{ objectFit: 'scale-down' }}
                            alt="Laptop"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PayLater;
