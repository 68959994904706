import React from 'react';

import DesktopNavBar from './DesktopNavBar/DesktopNavBar';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import styles from './NavBar.module.scss';

const NavBar = ({
    menuItems,
    mobileLogo,
    desktopLogo,
    setSubmitInputFocus
}) => {
    return (
        <>
            <div className={styles.mobileNavBar}>
                <MobileNavBar
                    menuItems={menuItems}
                    partnerLogo={mobileLogo}
                    setSubmitInputFocus={setSubmitInputFocus}
                />
            </div>
            <div className={styles.desktopNavBar}>
                <DesktopNavBar
                    menuItems={menuItems}
                    partnerLogo={desktopLogo}
                    setSubmitInputFocus={setSubmitInputFocus}
                />
            </div>
        </>
    );
};

export default NavBar;
